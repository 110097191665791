<template>
  <div class="rsf-select-wrapper relative mtrf mbrf">
    <select
      required
      id="mybatch_country"
      class="rsf-select"
      :style="`${
        Object.keys(this.displayedCountry).length == 0
          ? 'color: var(--rsf-2022-color-13)'
          : ''
      }`"
      @change="
        updateCountry();
        checkFormValidity();
      "
      v-model="displayedCountry"
    >
      <option isselected disabled :value="{}">
        {{ $t("mybatch_country_select_prompt") }}
      </option>
      <option v-for="country in countries" :key="country.id" :value="country">
        {{ country.countryname }}
      </option>
    </select>
    <svg
      :style="`transform: rotate(180deg) translateY(50%)`"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17 15L12 10L7 15"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
import axios from "axios";
import { EventBus } from "@/event-bus";

export default {
  name: "countryselect",
  props: {
    siteLanguage: String,
    checkFormValidity: Function,
  },
  data() {
    return {
      displayedCountry: {},
      countries: [],
    };
  },
  created() {},
  mounted() {
    console.log("siteLang");
    console.log(this.siteLanguage);

    let that = this;
    let url =
      process.env.VUE_APP_RSF_API + "/public-api/list-countries/mybatch/json";
    axios
      .post(url, { lang: this.siteLanguage })
      .then(function (response) {
        // Function to sort the array by countryname
        let sortedCountries = response.data.data.sort((a, b) => {
          // Convert countryname to lowercase for case-insensitive comparison
          let nameA = a.countryname.toLowerCase();
          let nameB = b.countryname.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        //put other country (xx) at bottom
        let otherCountry = {};

        console.log(sortedCountries);

        for (let i = 0; i < sortedCountries.length; i++) {
          if (sortedCountries[i].isocode2 == "XX") {
            otherCountry = sortedCountries[i];
            sortedCountries.splice(i, 1);
          }
        }

        sortedCountries = that.sortCountriesWithSpecialChars(sortedCountries);

        if (Object.keys(otherCountry).length > 0) {
          console.log("push opther country");
          sortedCountries.push(otherCountry);
        }

        that.countries = sortedCountries;

        that.preselectCountry();
      })
      .catch(function (error) {});
  },
  methods: {
    sortCountriesWithSpecialChars(countries) {
      const regex = /^[\u3040-\u30FF\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF]/;

      countries.sort((a, b) => {
        const aStartsWithSpecialChar = regex.test(a.countryname);
        const bStartsWithSpecialChar = regex.test(b.countryname);

        if (aStartsWithSpecialChar && !bStartsWithSpecialChar) {
          return -1;
        }
        if (!aStartsWithSpecialChar && bStartsWithSpecialChar) {
          return 1;
        }

        return a.countryname.localeCompare(b.countryname);
      });

      return countries;
    },

    preselectCountry() {
      if (this.siteLanguage == "cn") {
        for (let i = 0; i < this.countries.length; i++) {
          if (this.countries[i].isocode2 == "CN") {
            this.displayedCountry = this.countries[i];
            EventBus.$emit(
              "eb_log",
              this.$options.name,
              "update_country",
              "emit"
            );
            EventBus.$emit("update_country", this.displayedCountry);
            return;
          }
        }
      } else {
        for (let i = 0; i < this.countries.length; i++) {
          if (this.countries[i].preselection_by_ip == true) {
            console.log("repselected by ip");
            console.log(this.countries[i]);
            this.displayedCountry = this.countries[i];
            EventBus.$emit(
              "eb_log",
              this.$options.name,
              "update_country",
              "emit"
            );
            EventBus.$emit("update_country", this.displayedCountry);
            return;
          }
        }
      }
    },
    updateCountry() {
      EventBus.$emit("eb_log", this.$options.name, "update_country", "emit");
      EventBus.$emit("update_country", this.displayedCountry);
    },
  },
};
</script>

<style scoped></style>
