<template>
  <layout :appHeight="appHeight">
    <router-view :siteLanguage="siteLanguage" :faqLink="faqLink"></router-view>
  </layout>
</template>

<script>
import axios from "axios";
import { EventBus } from "./event-bus";
import layout from "./components/layout.vue";

export default {
  name: "App",
  props: ["siteLanguage", "faqLink"],
  components: {
    layout,
  },
  data() {
    return {
      showLog: process.env.VUE_APP_SHOW_DEBUG_LOG,
      appHeight: 0,
    };
  },
  created() {
    if (this.siteLanguage == "") {
      this.$i18n.locale = "en";
      this.switchLocale();
    } else {
      this.$i18n.locale = this.siteLanguage;
      this.switchLocale();
    }

    //state manager
    EventBus.$on("eb_log", (component, msg, method) => {
      this.EBlog(component, msg, method);
    });
  },
  mounted() {
    console.log("vue app mounted");
    this.getHeight();
  },
  methods: {
    EBlog(component, msg, method) {
      if (this.showLog == "false") {
        return;
      }
      console.log("++++++");
      if (method == "on") {
        console.log(
          "EventBus:on --- " + component + " received event: " + msg + "."
        );
      }
      if (method == "emit") {
        console.log(
          "EventBus:emit --- " + component + " emitted event: " + msg + "."
        );
      }
      console.log("++++++");
    },
    preSelectCountry() {
      console.log("preselect country");
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].preselection_by_ip) {
          console.log("prselected by IP!");
          console.log(this.countries[i].isocode2);
          this.selectedCountry = this.countries[i];
          this.updateCountry(this.countries[i]);
          if (this.countries[i].isocode2 == "US") {
            console.log("us - switch to imperial");
            this.measurement = "imperial";
          } else {
            console.log("use metric system");
            this.measurement = "metric";
          }
          return;
        }
      }

      console.log("no preselection by ip, checking site locale");

      if (this.siteLanguage == "cn") {
        this.findCountry("CN");
        return;
      } else if (this.$i18n.locale == "de") {
        this.findCountry("DE");
      } else if (this.$i18n.locale == "fr") {
        this.findCountry("FR");
      } else if (this.$i18n.locale == "en") {
        this.findCountry("GB");
      } else if (this.$i18n.locale == "br") {
        this.findCountry("GB");
      } else if (this.$i18n.locale == "ja") {
        this.findCountry("GB");
      } else {
        console.log("use fall back country");
        this.findCountry("US"); // fallback US
      }
    },

    findCountry(isocode) {
      console.log("find country: " + isocode);
      for (let a = 0; a < this.countries.length; a++) {
        if (this.countries[a].isocode2 == isocode) {
          this.selectedCountry = this.countries[a];
          this.updateCountry(this.countries[a]);
        }
      }
    },

    switchLocale() {
      var locale = this.$i18n.locale;
      const to = this.$router.resolve({ params: { lang: locale } });
      this.$router.push(to.location).catch((err) => {
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.log(err);
        }
      });
    },
    getHeight() {
      setTimeout(() => {
        this.appHeight =
          document.getElementById("mybatch_wrapper").clientHeight + 10;
      }, 500);
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-active {
  transition-delay: 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
