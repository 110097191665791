import MyBatch from './components/MyBatch.vue'
import ThankYou from './components/ThankYou.vue'
import BatchNotFound from './components/BatchNotFound.vue'

export default [
    {
        path: '/:lang', 
        component: MyBatch,
    },
    {
        path: '/:lang/batch-not-found', 
        component: BatchNotFound,
    },
    {
        path: '/:lang/thank-you', 
        component: ThankYou,
    },
    {
        path: "*",
        redirect: "/en",
        component: MyBatch,
    }
]