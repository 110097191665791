<template>
  <div class="py4">
    <h2 class="h1 open-sans-bold color-black mb2" v-html="$t('mybatch_thank_you')"></h2>
    <p class="h4 open-sans-regular color-black mb3">{{ $t('mybatch_email_inform') }}</p>
    <router-link to="/" class="rsf-btn rsf-btn-primary color-white bg-color-1">{{ $t('mybatch_go_back') }}</router-link>
  </div>
</template>

<script>

import axios from "axios";
import { EventBus } from "../event-bus";
import { RouterLink } from "vue-router";

export default {
  name: 'BatchNotFound',
  props: {
    siteLanguage: String
  }
}
</script>

<style scoped>

</style>
