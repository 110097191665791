<template>
  <form id="mybatch_form" style="scroll-margin: 200px"  @submit.prevent="submitForm">
    <h2 class="h1 helvetica-bold color-black mb3">{{ $t('mybatch_headline') }}</h2>
    <p class="h4 open-sans-bold color-black">{{  $t('mybatch_subline')  }}</p>
    <p class="h5 open-sans-regular color-14 mb3">{{  $t('mybatch_subline_2')  }} <a class="open-sans-regular color-1 pointer" style="text-decoration: none;" target="_blank" rel="noopener" :href="faqLink">{{ $t('mybatch_subline_link') }}</a></p>

    <label for="mybatch_no" class="block open-sans-bold color-black h5 pointer">{{ $t('mybatch_label_batch_no') }} <span class="color-6">*</span></label>
    <input required class="rsf-input mtrf mb1" id="mybatch_no" type="text" v-model="batchNo" @keyup="checkFormValidity">
    <p class="h6 color-14 open-sans-regular mbrf">{{ $t('mybatch_10_digits') }}</p>

    <label for="mybatch_name" class="block open-sans-bold color-black h5 pointer">{{ $t('mybatch_label_name') }} <span class="color-6">*</span></label>
    <input required class="rsf-input mtrf mbrf" id="mybatch_name" type="text" v-model="name" @keyup="checkFormValidity">
    
    <label for="mybatch_lastname" class="block open-sans-bold color-black h5 pointer">{{ $t('mybatch_label_lastname') }} <span class="color-6">*</span></label>
    <input required class="rsf-input mtrf mbrf" id="mybatch_lastname" type="text" v-model="lastname" @keyup="checkFormValidity">
    
    <label for="mybatch_country" class="block open-sans-bold color-black h5">{{ $t('mybatch_label_country') }} <span class="color-6">*</span></label>
    <countryselect
      :siteLanguage="siteLanguage"
      :checkFormValidity="checkFormValidity"
    />

    <label for="mybatch_email" class="block open-sans-bold color-black h5 pointer">{{ $t('mybatch_label_email') }} <span class="color-6">*</span></label>
    <input required class="rsf-input mtrf " id="mybatch_email" type="email" :placeholder="$t('mybatch_email_placeholder')" v-model="email" @keyup="checkFormValidity">
    
    <div class="col-12 h1px bg-color-12 my3"></div>

    <p class="h5 color-black open-sans-bold mb1">{{ $t('mybatch_join_newsletter') }}</p>
    <label class="checkbox-input-wrapper flex items-center pointer gap1 open-sans-regular h6 color-14 mb3" for="mybatch_newsletter">
      <input id="mybatch_newsletter" v-model="joinNewsletter" class="w-fit-content" type="checkbox"  name="mybatch_newsletter">
        {{ $t('mybatch_join_newsletter_label') }}
      <span class="checkbox-checkmark"></span>
    </label>

    <button type="submit" :class="`rsf-btn rsf-btn-primary color-white bg-color-1 h5 open-sans-semibold ${isValid ? '' : 'pointer-events-none op50'}`">{{ $t('mybatch_form_send_btn_label') }}</button>

    <div class="col-12 h1px bg-color-12 my3"></div>
    
    <p class="h6 open-sans-regular color-14">*<span class="open-sans-regular color-14" v-html="$t('myBatch_bold_tm')"></span> {{ $t('mybatch_notice_2') }}</p>
    <p class="h6 open-sans-regular color-14 pb3">**{{ $t('mybatch_notice_3') }}</p>
    
  </form>
</template>

<script>

import axios from "axios";
import { EventBus } from "../event-bus";
import Countryselect from "./countryselect.vue";

export default {
    name: 'MyBatch',
    data() {
        return {
            batchNo: '',
            name: '',
            lastname: '',
            selectedCountry: {},
            email: '',
            joinNewsletter: false,
            isValid: false
        };
    },
    props: {
      siteLanguage: String,
      faqLink: String
    },
    components: { Countryselect },
    created() {
        EventBus.$on('update_country', (payload) => {
            EventBus.$emit('eb_log', this.$options.name, 'update_country', 'on');
            this.selectedCountry = payload
        });
    },
    methods: {
      submitForm(){
        let browserLang = navigator.language || navigator.userLanguage;
        let url = process.env.VUE_APP_RSF_API + '/public-api/batches/get-result/json'
        let payload = {
          batch: this.batchNo,
          firstname: this.name,
          name: this.lastname,
          country_id: this.selectedCountry.id,
          lang: this.siteLanguage,
          email: this.email,
          recieve_newsletter: this.joinNewsletter,
          browserLang: browserLang
        }

        axios
          .post(url, payload)
          .then(response => {
            if(response.data.batchFound){
              this.$router.push({
                path: '/' + this.siteLanguage + '/thank-you/'
              })
            }else{
              this.$router.push({
                path: '/' + this.siteLanguage + '/batch-not-found/'
              })
            }

            // document.body.scrollTop = 0; // For Safari
            // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
              document.getElementById('mybatch_form').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

          })
          .catch(error => {
            console.log(error)
          })
            
          
      },
      checkFormValidity(){
        if(this.batchNo == ''){
          this.isValid = false;
          return; 
        }
        if(this.name == ''){
          this.isValid = false;
          return; 
        }
        if(this.lastname == ''){
          this.isValid = false;
          return; 
        }
        if(Object.keys(this.selectedCountry).length == 0){
          this.isValid = false;
          return; 
        }
        if(this.email == ''){
          this.isValid = false;
          return; 
        }
        this.isValid = true;
      }
    }
}
</script>

<style scoped>
</style>
