<template>
  <div id="mybatch_wrapper" class="max-width-3 col-12 mx-auto py3  border-box px2">
      <Transition name="fade">
        <slot></slot>
      </Transition>
  </div>
</template>

<script>

export default {
  name: 'layout',
  props: {
    appHeight: Number
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
  img{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    width: 100%; 
    object-fit: cover; 
    opacity: 0.4;
    height: 100%; 
  }
</style>
